@use "sass:math";

@import "../../../../../stylesheets/modules_new/styles/components/mixins/row-card";

@mixin  create-row-card { 
    @include create-row-card_private;
    
    &_card {
        border-top-left-radius: $panel-border-radius;
        background-color: $brand-color-24;
    }

    &_holdable{
      h4{
        margin-bottom: 0;
      }
      .flight{
        &__pax-info{
          &_card{
            &_header {
              padding-bottom: 0;
            }
          }
        }
      }
      .panel {
        margin-bottom: 0rem;
      }
    }
}

@mixin  create-row-card-header { 
    @include create-row-card-header_private; 
    &_card {
        &_header {
          padding:$base-padding*0.1 0;
          background-color: $brand-color-24;
          margin-left: 0;
          margin-right: 0;
          color: $brand-color-1;
          margin-top: 0;
          border-top-left-radius: $panel-border-radius;
          padding: $base-padding*0.5 0 0;
          button{
            color: $brand-color-24;
            margin-top: math.div($base-margin, 30);
          }
        }
        .header_text{
            padding-left: 1.25rem;
        }
      }
      &_header {
        background-color: transparent;
        margin-top: math.div($base-margin, 3);
        border-bottom: $border-width solid $brand-color-2;
        padding: 0;
        ul {
          margin-left: 0;
          margin-right: 0;
          > li {
            background-color: $brand-color-2;
            border-top-left-radius: $panel-border-radius;
            color: $brand-color-24;
            width: 20%;
            margin-right: 5%;
            padding: $base-padding*0.1;
            &:nth-child(2) {
              width: 20%;
              text-align: center;
            }
            &:last-child {
              width: 20%;
              text-align: center;
              margin-right: 0;
            }
            
          }
        }
    }  
}


small, .small{
  font-weight: 600;
}