@import "../../../../../../stylesheets/modules_new/styles/components/commons/base/base-variables";

$brand-color-1: #ed1d24;
$brand-color-2: #0054a6;
$brand-color-3: #65c6d7;
$dark: #404040;
$brand-color-23: $dark;
$brand-color-24: $light;
$brand-color-25: $gray;
$brand-color-26: $gray-light;

$brand-color-27: #caddf0;
$brand-color-28: #d7ebfe;
$brand-color-29: linear-gradient(to left, $brand-color-2 10%, $brand-color-1 60%);
$brand-color-30: saturate($brand-color-26,30);
$brand-color-31: desaturate($brand-color-2, 40);
$brand-color-32: lighten($brand-color-3,30);
$brand-color-33: #ca003d;
$brand-color-34: #ff5f00;

$font-family-sans-serif:   "Open Sans", sans-serif;
$font-family-base:          $font-family-sans-serif;
$font-family-autosuggest:   BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;

$panel-border-radius: 0;


$font-size-h7: 0.95rem !default;