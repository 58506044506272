@import "../../../../../stylesheets/modules_new/styles/components/mixins/flight-detail-row";

@mixin create-flight-detail-row {
    @include create-flight-detail-row_private;

    .flexi-time{
        font-size: 0.9rem;
        color: lighten($brand-color-1, 15);
        line-height: 1;
    }

    .detail{
        &__duration{
            &_flight-duration{
                &::before, &::after{
                    position: absolute;
                    height: 0.0625rem;
                    content: "";
                    width: 2.375rem;
                    background-color: $brand-color-25;
                    margin-top: -0.75rem;
                }
                &::after{
                    right: 8.5rem;
                }
                &::before{
                    left: 15.3125rem;
                }
            }
            &_flight-duration{
                &::before, &::after{
                    @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size - 1px) {
                        width: 1.575rem;
                    }
                    @media screen and (max-width: $sm-screen-size) {
                        width: 0.375rem;
                    }
                }
                &::after{
                    @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size - 1px) {
                        right: 7.7rem;
                    }
                    @media screen and (max-width: $sm-screen-size) {
                        right: 11.5rem;
                        margin-top: -1.5rem;
                    }
                }
                &::before{
                    @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size - 1px) {
                        left: 13.225rem;
                    }
                    @media screen and (max-width: $sm-screen-size) {
                        left: 15.8125rem;
                        margin-top: -0.7rem;
                    }
                }
            }
        }
    }

    &__from, &__to{
        text-align: center;
        &_date{
            color: $brand-color-2;
            display: block;
        }
    }
    &__duration{
        &_time{
            color: $brand-color-2;
            &::before, &::after{
                background-color: lighten($brand-color-25, 20);
            }
        }
    }
    &__fname, &__fid{
        color: $brand-color-2;
    }
    &__seat_count_green{
        color: $brand-color-23;
    }
}

.flight__review_row {
    .change__info {
        padding-left: 0.8rem;
        top: -0.55rem;
        width: 17%;
    }
    .details__details_layover:before{
        width: 30.6%;
        top: 0.1875rem;
        right: 1.5%;
        margin-left: unset;
    }
    .details{
        &__details{
            &_layover,&_return{
                &:after{
                    margin-left: unset;
                    width: 49.5%;
                    left: 1.5%;
                    top: 0.1875rem;
                }
            }
        }
    }
    .details{
        &__details{
            &_layover{
                margin-top: $base-margin;
                padding-bottom: $base-padding;
            }
        }
    }
    
    .change__info {
        @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size - 1px) {
            padding-left: 1.5rem;
        }
    }
    .details__details_layover:before{
        @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size - 1px) {
            width: 27.5%; 
        }
    }
}