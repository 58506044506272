@use "sass:math";

@import "../../../../../stylesheets/modules_new/styles/components/mixins/package-details";

@mixin  create-package-detail-modal-container { 
	@include create-package-detail-modal-container_private;
    
    .package-details, .package-hotel-highlights{
        .package-detail{
            &__sidebar{
                background-color: transparent;
                border-top-left-radius: $panel-border-radius;
            }
            &__content{
                padding: 0 math.div($base-padding, 6) 0 $base-padding*0.5;
                font-size: 0.82rem;
            }
        }
        .sidebar{
            &__tabs{
                .active{
                    background-color: $brand-color-2;
                    border-top-left-radius: $panel-border-radius;
                    a{
                        color: $brand-color-24;
                    }
                }
                > li{
                    border-bottom: $border-width*5 solid $brand-color-24;
                    background-color: $brand-color-27;
                    &:hover{
                        border-top-left-radius: $panel-border-radius;
                    }
                    > a{
                        font-size: 0.82rem;
                        color: $brand-color-23;
                        padding-top: math.div($base-padding, 3);
                        padding-bottom: math.div($base-padding, 3);
                    }
                }
            }
            &__back-button{
                display: none;
            }
        }
        .package-overview, .package-incl-excl, .package-canc-policy{
            &__header{
                background-color: $brand-color-2;
                color: $brand-color-24;
                border-top-left-radius: $panel-border-radius;
                padding-left: 1.25rem;
            }
            &__content{
                padding: math.div($base-padding, 6) $base-padding*0.5 $base-padding*0.5;
                .glyphicon{
                    color: $brand-color-1;
                    font-size: 1rem;
                }
            }
        }
        .package-overview{
            &__content {
                padding: $base-padding*0.5 $base-padding*0.5 math.div($base-padding, 6);
                text-align: center;
                color: $brand-color-2;
            }
        }
        .search{
            &__result{
                &_header{
                    background-color: $brand-color-2;
                    color: $brand-color-24;
                    border-top-left-radius: $panel-border-radius;
                    padding-left: $base-padding*0.5;
                    padding-bottom: math.div($base-padding, 6);
                }
                &_footer{
                    background-color: $brand-color-31;
                }
                &_trip{
                    padding-bottom: math.div($base-padding, 3);
                }
            }
        }
        .result{
            &__book{
                &_button{
                    padding: 0.3rem 0;
                    font-size: 0.725rem;
                    border-bottom-right-radius: $panel-border-radius;
                    background-color: transparent;
                    background: $brand-color-29;
                    &:hover{
                        color: $brand-color-16;
                        background-color: transparent;
                        opacity: 0.8;
                    }
                }
            }
            &__flight{
                &_details{
                    &_button{
                        background-color: $brand-color-31;
                    }
                }
            }
        }
        .dp-hotel-room-list{
            &_main{
                padding-top:math.div($base-padding, 3);
            }
            &__header{
                border: 0;
                width: 30%;
                float: left;
                box-shadow: unset;
                margin-bottom: 0;
                padding: 0;
                &_change-room{
                    width: 100%;
                    float: left;
                    .change-room{
                        &__button{
                            border-radius: math.div($panel-border-radius, 3);
                            font-size: 0.725rem;
                        }
                    }
                }
            }
        }
        .hotel-room-list{
            &__room-container{
                border: 0;
                width: 70%;
                float: left;
                padding: 0;
                box-shadow: unset;
                font-size: 0.82rem;
                .room-container{
                    &__room-number{
                        width: 13%;
                        margin-left: 2%;
                    }
                    &__room-detail{
                        width: 80%;
                    }
                }
            }
        
        }
        .package-itinerary{
            &__content{
                padding-bottom: $base-padding*0.5;
                p{
                    color: $brand-color-23 !important;
                    font-family: $font-family-base !important;
                    font-size: 0.82rem !important;
                }
            }
            
            &__header{
                background-color: $brand-color-2;
                color: $brand-color-24;
                border-top-left-radius: $panel-border-radius;
                padding-left: 1.25rem;
            }
            ul{
                margin-top: $base-margin*0.5;
                margin-bottom: $base-margin*0.5;
                margin-left: $base-margin*0.1;
            }
            &__amenity{
                padding-top: 0;
                padding-bottom: math.div($base-padding, 6);
            }
        }
        .package-incl-excl{
            &__content{
                line-height: 1.375rem;
            }

        }
        .package-canc-policy{
            &__content{
                padding-top: $base-padding*0.5;
            }
        }
        .package-rate{
            &_card{
                background-color: $brand-color-2;
                padding-top: math.div($base-padding, 6);
                padding-bottom: math.div($base-padding, 6);
            }
            &__total-price{
                width: 30%;
                text-align: right;
                color: $brand-color-24;
                font-size: 0.82rem;
            }
            &__currency{
                color: $brand-color-24;
            }
            &__category{
                width: 30%;
                color: $brand-color-24;
            }
            &__star-rating{
                width: 40%;
                .glyphicon{
                    color: $brand-color-24;
                    font-size: 0.82rem;
                }
            }
            &_footer{
                background-color: transparent;
                margin-top: $base-margin*0.5;
                border-bottom: $border-width solid $brand-color-31;
                .package-rate{
                    &_details{
                        background-color: $brand-color-31;
                        text-transform: uppercase;
                        color: $brand-color-24;
                        border-top-left-radius: $panel-border-radius;
                        padding: $base-padding*0.1;
                    }
                }
            }
        }
        .rate-products{
            border: 0;
            padding: math.div($base-padding, 6) 0;
            &__content {
                .content-hotels{
                    border-bottom: $border-width solid $brand-color-31;
                    &__details-row {
                        .hotel{
                            &__highlights{
                                margin-top: math.div($base-margin, 6);
                                > button{
                                    background-color: $brand-color-31;
                                    color: $brand-color-24;
                                    padding: $base-padding*0.1;
                                    border-top-left-radius: $panel-border-radius;
                                    text-transform: uppercase;
                                }
                            }
                            &__city, &__name, &__roomtype, &__no-days, &__star-rating{
                                text-align: center;
                                color: $brand-color-23;
                                font-size: 0.8rem;
                                font-weight: 600;
                            }
                            &__star-rating{
                                .glyphicon{
                                    color: $brand-color-1;
                                    font-size: 0.9rem;
                                }
                            }
                        }
                    }
                }
            }
            &__header{
                border-bottom: $border-width solid $brand-color-31;
                > h4{
                    text-align: center;
                    font-size: 0.8rem;
                    font-weight: 600;
                }
            }
        }
        .package-hotel-highlights{
            &__sidebar{
                background-color: transparent;
                border-top-left-radius: $panel-border-radius;
            }
            &__content{
                padding: 0 0 0 $base-padding*0.5;
            }
        }
        .package-hotel-description{
            &__header{
                background-color: $brand-color-2;
                color: $brand-color-24;
                border-top-left-radius: $panel-border-radius;
                padding-left: $base-padding*0.5;
            }
            &__content{
                padding-top: math.div($base-padding, 3);
                padding-bottom: $base-padding*0.5;
                padding-left: $base-padding*0.5;
            }
        }
    }

    .package-rate-details{
        padding-left: 0rem;
        padding-right: 0rem;
        padding-bottom: 0rem;
        &__content{
            border-left: $border-width solid $brand-color-2;
            border-right: $border-width solid $brand-color-2;
            border-top: $border-width solid $brand-color-2;
            border-bottom: $border-width solid $brand-color-2;
        }
    }
    
    .package-table{
        th{
            background-color: $brand-color-30;
            color: $brand-color-24;
        }
        &__package-rate{
            text-transform: capitalize;
        }
        &__currency{
            float: unset;
            text-align: right;
            margin-right: $base-margin*0.1;
            color: $brand-color-2;
            font-size: .8rem;
        }
        td{
            &:nth-child(4){
                color: $brand-color-1;
                font-size: 1.125rem;
            }
            &:last-child{
                display: flex;
                div{
                    margin-top: 0.5rem;
                    margin-left: 10%;
                }
            }
            >.result{
                &__book{
                    &_button{
                        margin-left: 5%;
                    }
                }
            }
        }
    }

}

.sidebar{
    &__back-button{
        margin: 0 0 math.div($base-margin, 3);
        background-color: $brand-color-2;
        border: $border-width solid $brand-color-2;
        padding: $base-padding*0.5;
        border-top-left-radius: $panel-border-radius;
    }
}



