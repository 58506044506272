@use "sass:math";

@import "../../../../../stylesheets/modules_new/styles/components/mixins/search-form-nav";

@mixin  create-search-form-nav { 
    @include create-search-form-nav_private; 

    &__search-nav {
        background-color: unset;
    }
    &__search-nav_list {
        li {
         border-right: 0;
         border-top-left-radius: $panel-border-radius;
         
        a:hover, &:hover {
          background-color: $brand-color-1;
          border-top-left-radius: $panel-border-radius;
        }
      }
      li{
        @media screen and (max-width: $lg-screen-size) {
          padding-left: 1.11875rem;
          padding-right: 1.125rem;
          font-size: 0.75rem;
         }
           @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size - 1px) {
             padding-left: 0.3rem;
             padding-right: 0.3rem;
             font-size: 0.689rem;
             margin-right: 0.125rem;
             margin-bottom: 0.125rem;
          }
          @media screen and (min-width: $lg-screen-size ){
             padding-left: 0.78rem;
             padding-right: 0.79rem;
             font-size: 0.6875rem;
             margin-right: 0.125rem;
             margin-bottom: 0.125rem;
         }
         @media screen and (max-width: $sm-screen-size) {
             padding-left: 0.2rem;
             padding-right: 0.15rem;
             font-size: .65rem;
             margin-right: 0.125rem;
             margin-bottom: 0.125rem;
         }
         a{
          @media screen and (max-width: $sm-screen-size) {
            padding: math.div($base-padding, 3) 0.3rem;
          } 
        }
      }
    }
}