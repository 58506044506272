@use "sass:math";

@import "../../../../../stylesheets/modules_new/styles/components/mixins/search-form";


@mixin create-search-form {
    @include create-search-form_private;

    &__search-form-container {
          background-image: none;
          min-height: 30vh;
          padding-bottom: 0;
            label{
                font-size: 0.8rem;
            }
            input[type="text"], select{
              background-color: $brand-color-24;
              border-color: $brand-color-23;
              border-radius: math.div($panel-border-radius, 3);
              padding: .5rem .5rem;
              max-height: 1.625rem;
              position: relative;
              color: saturate($brand-color-26, 50);
              font-size: 0.800rem;
              outline: none;
            }
            input[type="text"] {
              border-color: $brand-color-23
            }
            select{
              color: saturate($brand-color-26, 50);
              padding: 0.16rem 0.5rem 0.18rem;
              outline: none;
              height: 2.125rem;
              border-color: $brand-color-23;
            }
            .multi-select .multi-select-field{
              width: 100%;
              padding: 0.1rem 0.5rem 0.5rem;
              background: none;
              font-size: 0.875rem;
              vertical-align: middle;
              line-height: normal;
              background-color: $brand-color-24;
              border: $border-width solid;
              border-radius: math.div($panel-border-radius, 3);
              border-color: $brand-color-23;
              max-height: 1.625rem;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: saturate($brand-color-26,50);
            }
            .multi-select .multiselect-parent{
              margin-top: 0.25rem;
            }
            .input-group-btn .btn{
              border: $border-width solid $brand-color-23;
            }
            ::placeholder{
              color: saturate($brand-color-26, 50);
            }
            .air__search-form_header-title {
              width: 30%;
              float: left;
              background: $brand-color-29;
              h2{
                padding-left: $base-padding;
                margin: 0.4rem 0rem 0.65rem;
                color: $brand-color-24;
                font-size: 1.5rem;
              }
            }

           .air__search-trip_options{
              width: 70%;
              float: left;
              border-top: $border-width*2 solid saturate($brand-color-26,40);;
              border-bottom: $border-width*2 solid saturate($brand-color-26,40);;
            }

            .air__search-trip-type{
              background-color: transparent;
              padding-top: 0;
              padding-left: 4.495rem;
              margin-bottom: 0.50rem;
              margin-top: 0.35rem;
            }
            

            .air__search-trip-type .radio-inline{
              border: 0px solid $brand-color-23;
              border-radius: $panel-border-radius;
              margin-right: math.div($base-margin, 3);
              padding: math.div($base-padding, 30) 0.325rem 0;
              color: $brand-color-2;
              font-size: 0.8rem;
              margin-top: math.div($base-margin, 6);
              margin-bottom: 0;
            }
      
            .air__search-form_header {
              font-size: 0.7rem;
              color: $brand-color-2;
              font-weight: 600;
              margin-left: -$base-margin;
              margin-right: -$base-margin;
              display: flex;
              margin-bottom: $base-margin;
          }

          .air__page-header{display: none;}

          .air__flexi-container{
            width: 30%;
            float: left;
            margin-left: 1.125rem;
          }
          
          .sidebar{
            padding-left: $base-padding*0.1;
            margin-top: 2.375rem;
            .search-form-extras{
              display: none;
            }
            .sidebar-img-container{
              p{margin-bottom: 0;}
              &_iframe{
                    position: relative;
                    min-height: 45.625rem;
                    overflow: hidden;
                      iframe {
                        position: absolute;
                        top:0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                      }
                  }
            }
          }

          .sidebar-img-container p img {
            width: 100%;
            margin-bottom: $base-margin*0.5;
          }

          .sidebar-img-container p:last-child > img {
            width: 50%;
          }


          @media screen and (min-width: $lg-screen-size ){
            min-height: 30vh;
          }
          label{
            @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
              font-size: 0.775rem;
            }
            @media screen and (max-width: $sm-screen-size) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              margin-bottom: 0;
            }
          }
          input[type="text"], select{
            @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
              padding: 0.5rem;
            }
          }
          select{
            @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
              padding: 0.16rem 0.5rem 0.18rem;
            }
          }
          .multi-select .multi-select-field{
            @media screen and (max-width: $md-screen-size) {
              padding: 0.1rem math.div($base-padding, 3) 0.5rem;
            }
          }
          .multi-select .multiselect-parent{
            @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
              margin-top: 0.2rem;
            }
          }
          .air__search-form_header-title {
            h2{
              @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
                padding-left: 1.25rem;
              }
            }
          }
          .air__search-trip-type{
            @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
              padding-left: 3.995rem;
              margin-top: 0.5rem;
            }
          }
          .air__search-trip-type .radio-inline{
            @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size){
              padding: 0rem 0.225rem;
            }
            @media screen and (max-width: $sm-screen-size) {
              font-size: 0.68rem;
            }
          }
          .air__search-form_header {
            @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
              margin-left: -1.25rem;
              margin-right: -1.25rem;
            }
            @media screen and (max-width: $md-screen-size) {
              font-size: 0.62rem;
            }
          }
    }

    &__search-form {
      min-height: 27.5rem;
      margin-bottom: $base-margin*0.5;
      &_header{
        color: $brand-color-2;
        h2{
          font-size: 1.3rem;
          font-weight: 700;
        }
      }
      @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
        min-height: 26.0rem;
        padding: 1.25rem;      
      }
      @media screen and (max-width: $sm-screen-size) {
          min-height: 28.2rem;
          padding: 1.625rem $base-padding;
      }
    }

    &__search-location {
      margin-bottom: math.div($base-margin, 3);
      margin-right: math.div(-$base-margin, 6);
      .search-location_autosuggestion {
        @include make-one-third;
		width: 32%;
        label{
          color: $brand-color-2;
          background-color: transparent;
          border-radius: math.div($panel-border-radius, 3);
          padding: 0.1rem 0rem 0rem;
          font-size: 0.8rem;
        }
      }
      .search-location_search-text {
        @include make-one-third;
      }
      @media screen and (max-width: $md-screen-size) {
        margin-bottom: 1.125rem;
      }
      @media screen and (max-width: $sm-screen-size) {
        margin-bottom: 0.1rem;
      }
    }

    &__search-date, &__hub-surcharge, &__search-date_hotel-dates{
      .search-date_select {
        @include make-one-third;
        label{
          color: $brand-color-2;
          background-color: transparent;
          border-radius: math.div($panel-border-radius, 3);
          padding: 0.1rem 0rem $base-padding*0.1;
          font-size: 0.8rem;
          margin-bottom: 0;
        }
        .input-group > input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
          border: $border-width solid $brand-color-23;
      
        }

        .input-group-btn > button{
          border-top-right-radius: math.div($panel-border-radius, 3);
          border-bottom-right-radius: math.div($panel-border-radius, 3);
          border-left: 0;
        }
        label{
          @media screen and (max-width: $sm-screen-size) {
            padding-bottom: 0rem;
          }
        }
        
      }
      .date-range-picker{
        &__to-date{
          float: right;
          margin-top: 0.9rem;
          margin-bottom: -0.4rem;
          margin-right: $base-margin*0.1;
        }
      }
    }

    &__search-date_hotel-dates{
      .date-range-picker{
        &__to-date{
          float: left;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .search-date{
        &_select{
          margin-top: math.div($base-margin, 3);
          width: 35%
        }
      }
    }

    &__search-traveller {
      @include make-row;
      margin-right: math.div(-$base-margin, 6);
      &_adult,
      &_child,
      &_infant,
      &_class {
        label{
          @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
            font-size: 0.705rem;
            font-weight: 600;
          }
        }
        @include make-one-sixth;
        text-align: left;
      }
      .number-stepper{
        .input-group-btn {
        &:first-child{
        > .btn{
            border-top-left-radius: math.div($panel-border-radius, 3);
            border-bottom-left-radius: math.div($panel-border-radius, 3);
            border-right: 0;
          }
        }
        &:last-child{
          > .btn{
              border-top-right-radius: math.div($panel-border-radius, 3);
              border-bottom-right-radius: math.div($panel-border-radius, 3);
            }
          }
      }
      }
    }

    &__search-currency {
      .country-selector__wrapper {
        @include make-one-sixth;
        text-align: left;
        label{
          @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
            font-size: 0.705rem;
            font-weight: 600;
          }
        }
      }
      .airline-selector__wrapper {
        @include make-one-sixth;
        text-align: left;
        .multi-select{
          margin-top: 0;
          span{
          font-size: 0.775rem;
          margin-bottom: 0.05rem;
          }
          @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
              span{
                    font-weight: 600;
                  }
            }
          }
      }
      @media screen and (max-width: $sm-screen-size) {
        margin-bottom: 0.2rem;
      }
    }

    &__flexi-flights {
      label{
        border: $border-width solid $brand-color-23;
        border-radius: 0;
        font-size: 0.75rem;
        padding: $base-padding*0.1 0.875rem $base-padding*0.1 0.5rem;
        input{
          margin-right: math.div($base-margin, 6);
          vertical-align: middle;
          background: $brand-color-19;
          border: $brand-color-19 $border-width solid;
          margin-top: -$base-margin*0.1;

        }
      }

    }
    
    &__search-trip-type_detail {
      .radio-inline__trip-type > input{
        vertical-align: middle;
        margin-right: math.div($base-margin, 30);
        margin-top: -0.125rem;
        @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
          vertical-align: middle;
          margin-right: math.div($base-margin, 30);
        }
      }
    }

    &__search-trip-type{
      .radio-inline{
        width: auto;
        @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
          width: auto;
        }
      }
    }

    &__search-submit {
      margin-right: math.div(-$base-padding, 6);
      &_button {
        padding-left: $base-padding*0.5;
        padding-right: $base-padding*0.2;
        width: 33%;
        .submit_button {
          border-radius: math.div($panel-border-radius, 3);
        }
      }
    }

    &__search-multicity {
      margin-bottom: $base-margin*0.5;
      &_date {
        .search-date_select{
          .input-group > input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
        
          }

          .input-group-btn > button{
            border-top-right-radius: math.div($panel-border-radius, 3);
            border-bottom-right-radius: math.div($panel-border-radius, 3);
            border-left: 0;
            .glyphicon{
              top:0rem;
            }
          }

          label{
            color: $brand-color-2;
            background-color: transparent;
            border-radius: math.div($panel-border-radius, 3);
            padding: 0.1rem 0rem $base-padding*0.1;
            font-size: 0.775rem;
          }
        }
      }
      &_location {
        .multicity_autosuggestion{
          label{
            color: $brand-color-2;
            background-color: transparent;
            border-radius: math.div($panel-border-radius, 3);
            padding: 0.1rem 0rem $base-padding*0.1;
            font-size: 0.8rem;
          }
        }

      }
      &_flight-close{
        float: left;
        padding-top: 1.85rem;
      }
      &_hotel-dates{
        .hotel-dates{
            &__room-info{
                &_extra-bed{
                    padding-top: math.div($base-padding, 3);
                    font-weight: 700;
                    > input, label{
                        vertical-align: middle;
                        margin-right: math.div($base-margin, 6);
                        margin-bottom: 0;
                    }
                }
            }
        }
      }
    }
    &__advance-search {
      &_title{
        background-color: transparent;
        border-radius: math.div($panel-border-radius, 6);
      }
    }

    &__page-header{
      display: none;
    }

    &__search-footer {
      .image-banner-container__title {
        text-align: center;
        color: $brand-color-24;
        margin-top: $base-margin*0.1;
        margin-bottom: $base-margin*0.1;
        font-size: 0.7rem;
      }
      
      .image-banner-container__arrow-down {
        text-align: center;
      }
      
      .image-banner-container__arrow-down img {
        width: 1.25rem;
      }
    
      .image-banner-container {
        width: 16.66%;
        float: left;
        padding-left: $base-padding*0.5;
        padding-right: $base-padding*0.5;
      }
      
      .image-banner-container{
          &__package-banner{
            img {
              width: 100%;
            }
          }
      }
      &_iframe{
        position: relative;
        padding-bottom: 16.50%;
        height: 0;
        overflow: hidden;
          iframe {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            }
      }
    }
    &__advertise{
        padding: 0;
        background-color: transparent;
        margin-top: 2.375rem;
        display: none;
    }
    &__search-banner-container{
      &_iframe{
        position: relative;
        padding-bottom: 50%;
        overflow: hidden;
        border-bottom: 0 dashed $brand-color-1;
          iframe {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            }
      }
    }

    &__search-form_container {
      input[type="text"],
        select {
          border-color: $brand-color-23;
        }
        input[type="text"] {
          border-color: $brand-color-23;
        }
        label{
          @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
            font-size: 0.72rem;
          }   
        }
    }
}

.why-us-container {
  display: none;
}

.extra-container-for-space {
  margin-bottom: math.div($base-margin, 3);
  @media screen and (max-width: $md-screen-size) {
    margin-bottom: 1.5rem;
  }
}

.pax-info{
  &__search-form{
    &_container {
      select{
        border-color: $brand-color-23;
        option{
          &:hover{
            background-color: $brand-color-30;
          }
        }
      }
      .pax-info{
        &__search-date {
          .search-date{
            &_select{
              &::after{
                padding-top: 0.6375rem;
              }
            }
          }
        }
      }
      .room-info{
        &__type{
          &::after{
            padding-top: 0.6375rem; 
          }
        }
      }
      label{
        @media screen and (min-width: $md-screen-size) and (max-width: $lg-screen-size) {
          font-size: .7rem;
        }
      }
      select{
        border: $border-width solid $brand-color-23;
        max-height: 1.625rem;
        padding: 0.15rem 1rem 0.4rem;
      }
      input[type=text]{
        border: $border-width solid $brand-color-23;
        max-height: 1.625rem;
      }
    }
  }
  &__search-traveller{
    .input-group-btn {
      .btn{
        padding: .5rem 1rem;
        max-height: 2.125rem;
      }
    }
  }
  &__search-submit{
    &_button {
      .submit{
        &_button{
          background: $brand-color-29;
          background-color: transparent;
          &:hover, &:focus{
            background: $brand-color-29;
            opacity: 0.8;  
            background-color: transparent;
          }
        }
      }
    }
  }
}